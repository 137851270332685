import { useSelector } from 'react-redux';
import { NavLink } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { FaUserAlt } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";

import SearchBar from './SearchBar';
import CartIcon from './cart/CartIcon';
import logo from '../rolling-logo.png';

import { useEffect } from 'react';
import { useDispatch } from "react-redux";
import { searchResults, setActiveMenu, setSearchQuery } from "store/productsReducer";
import { useNavigate } from "react-router-dom";

function MenuBar({ currentUser }) {
  const pages = useSelector((store) => store.content.pages);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    //render
  }, [pages]);

  let showAdminBoard = false;
  if (!!currentUser) {
    showAdminBoard = currentUser.roles.includes("admin");
  }

  const goHome = (e) => {
    console.log("MenuBar: go home");
    if (!!currentUser) {
      console.log("MenuBar: go home => delete all queries");
      dispatch(setSearchQuery(''));
      dispatch(searchResults([]));
      dispatch(setActiveMenu({}));
      //navigate("/", { state: { query: '' } });
      navigate("/", { replace: true });
      navigate("/");
      document.title = "Rolling";
    }
  }

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className='px-3 '>

      <Navbar.Brand as={NavLink} to="/" onClick={goHome}>
        <img width="100px" src={logo} alt="Rolling System" className="d-inline-block align-top" />
      </Navbar.Brand>


      {!!currentUser && <SearchBar user={currentUser} />}


      {!!currentUser ? (
        // user-specific part
        <>
          <Navbar className="justify-content-end" id="search-navbar-nav">

            <Nav.Link id="cart-link" className='mx-2' style={{ paddingLeft: '32px' }} as={NavLink} to="/cart"><CartIcon /></Nav.Link>
            {!!showAdminBoard && (<Nav.Link className='mx-2' as={NavLink} to="/admin">Admin</Nav.Link>)}
            <Nav.Link className='mx-2' as={NavLink} to="/profile" title={currentUser.meta.firstName}><FaUserAlt /></Nav.Link>
            <Nav.Link className='' as={NavLink} to="/logout" style={{ borderLeft: "1px solid gray", paddingLeft: '16px' }} title="Logout"><RiLogoutBoxRLine color='red' /></Nav.Link>
          </Navbar>
        </>
      ) : (
        // public part
        <>
          <Navbar.Toggle aria-controls="search-navbar-menu" />
          <Navbar.Collapse className="justify-content-end" id="search-navbar-menu">
            <Nav className="ml-100">
              {!!pages &&
                pages
                  .filter(p => !!p.path)
                  .filter(p => p.path !== 'home')
                  .map((p) => (
                    <Nav.Link key={p.path} as={NavLink} to={`/${p.path}`}>{`${p.menu}`}</Nav.Link>
                  ))}

              <Nav.Link as={NavLink} id="login-link" to="/login">Login B2B</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </>
      )}



    </Navbar>

  );
}

export default MenuBar
