import { createSlice } from '@reduxjs/toolkit';
import { hashCode } from 'common/utils';

function appyAllFilters(state) {
    //let simbolClaseFilterActive = state.filters.simbolClaseSelected.length > 0;
    let claseFilterActive = state.filters.claseSelected.length > 0;
    let producatoriFilterActive = state.filters.producatoriSelected.filter(i => i > 0).length > 0;

    //let filterShopsIds = Object.keys(state.filters.byStock).filter(sId => state.filters.byStock[sId]);
    //let stockFilterActive = filterShopsIds.length > 0;
    // let stockFilterActive = activeShops.length > 0;
    console.log("appyAllFilters", claseFilterActive, producatoriFilterActive);

    if (!state.products) {
        return;
    }

    let newfilteredProducts = state.products
        .filter(product => {
            let ok = true;
            //ok = ok && product.Denumire.toLowerCase().includes(state.filters.byText);
            /*
                        if (simbolClaseFilterActive
                            && !state.filters.simbolClaseSelected[product.idSimbolClasa]) {
                            ok = false;
                        }
            */
            if (claseFilterActive) {
                if (!!state.clase[product.idClasa]) {
                    let hash = hashCode(state.clase[product.idClasa].display);
                    if (!state.filters.claseSelected.includes(hash)) {
                        ok = false;
                    }
                }
            }

            if (producatoriFilterActive
                && !state.filters.producatoriSelected[product.idProducator]) {
                ok = false;
            }
            /*
                        if (stockFilterActive) {
                            filterShopsIds.map(activeShopId => (
                                ok = ok && (product.stock
                                    .filter(s => (parseInt(s.idShop) === parseInt(activeShopId)))
                                    .filter(s => (s.stock > 0))
                                    .length > 0)
                            ))
                        }
            */
            return ok;
        });

    //countPriceRange(newfilteredProducts, state);

    // filter by price with the new ranges
    newfilteredProducts = newfilteredProducts
        .filter(product => {
            let ok = true;
            let shallow = Object.assign({}, product);
            // TODO check why is this affected
            let pretStr = shallow.pretSite;
            let pret = parseFloat(pretStr);

            ok = ok && (pret >= state.filters.byPrice.min);
            ok = ok && (pret <= state.filters.byPrice.max);
            return ok;
        });


    state.filteredProducts = newfilteredProducts;
    recount(state);
}


function recount(state) {
    // recount
    state.filteredCount = state.filteredProducts.length;
    state.filteredPages = Math.ceil(state.filteredCount / state.countPerPage);
}


function countPriceRange(products, state) {
    if (!products || !products.map) {
        state.priceRange.min = 0;
        state.priceRange.max = 100;
        return;
    }

    const prices = products.map(p => p.pretSite);

    if (!!prices && prices.length) {
        state.priceRange.min = Math.floor(Math.min(...prices));
        state.priceRange.max = Math.ceil(Math.max(...prices));
    } else {
        state.priceRange.min = 0;
        state.priceRange.max = 0;
    }
    if (parseInt(state.priceRange.max) === 0) {
        state.priceRange.max = 100;
    }
    console.log("countPriceRange", state.priceRange.min, state.priceRange.max);
}

function resetPriceRange(state) {
    //TODO: causes infinite loop 
    state.filters.byPrice.min = state.priceRange.min;
    state.filters.byPrice.max = state.priceRange.max;
}

function categoryComparator(a, b) {
    return b.count - a.count;
    /* sort by name?
    let catA = state.simbolClase[a.id];
    let catB =  state.simbolClase[b.id];
    
    if (catA.display.indexOf(' ') == 1) {
        catA.display = '0' + catA.display;
    }
   
    if (catB.display.indexOf(' ') == 1) {
        catB.display = '0' + catB.display;
    }
    
    if (catA.display > catB.display) {
        return 1;
    } 
    if (catA.display < catB.display) {
        return -1;
    } 
    return 0;
    */
}
function recountAllGroupSelectors(state) {
    let newSimbolClaseSelectedCounters = [];
    let newClaseSelectedCounters = [];
    let newProducatoriSelectedCounters = [];

    if (!state.filteredProducts || !state.filteredProducts.map) {
        state.counters.producatoriSelected = [];
        state.counters.claseSelected = [];
        state.counters.simbolClaseSelected = [];
        return;
    }

    state.filteredProducts.map(p => {
        if (!p) {
            return;
        }

        if (!!p.idSimbolClasa) {
            if (!!newSimbolClaseSelectedCounters[p.idSimbolClasa]) {
                newSimbolClaseSelectedCounters[p.idSimbolClasa]++;
            } else {
                newSimbolClaseSelectedCounters[p.idSimbolClasa] = 1;
            }
        }

        if (!!p.idClasa) {
            let c = state.clase[p.idClasa];
            if (!!c && !!c.display) {
                if (!!newClaseSelectedCounters[c.display]) {
                    newClaseSelectedCounters[c.display]++;
                } else {
                    newClaseSelectedCounters[c.display] = 1;
                }
            }
        }
        if (!!p.idProducator) {
            if (!!newProducatoriSelectedCounters[p.idProducator]) {
                newProducatoriSelectedCounters[p.idProducator]++;
            } else {
                newProducatoriSelectedCounters[p.idProducator] = 1;
            }
        }
    });

    let sortableCounters = [];
    Object.keys(newSimbolClaseSelectedCounters).map((id) => {
        sortableCounters.push({ id: id, count: newSimbolClaseSelectedCounters[id] })
    });
    sortableCounters.sort(categoryComparator);
    state.counters.simbolClaseSelected = sortableCounters;


    sortableCounters = [];
    Object.keys(newClaseSelectedCounters).map((display) => {
        sortableCounters.push({ display: display, count: newClaseSelectedCounters[display] })
    });
    sortableCounters.sort(categoryComparator);
    state.counters.claseSelected = sortableCounters;


    sortableCounters = [];
    Object.keys(newProducatoriSelectedCounters).map((id) => {
        sortableCounters.push({ id: id, count: newProducatoriSelectedCounters[id] })
    });
    newProducatoriSelectedCounters.sort(categoryComparator);
    state.counters.producatoriSelected = sortableCounters;
}

const productsSlice = createSlice({
    name: 'products',
    initialState: {
        searchQuery: "",
        activeMenu: {},
        menuTree: {},
        searchType: "include",
        products: {},
        filteredProducts: {},
        filters: {
            byText: '',
            byStock: [],
            byPrice: { min: 0, max: 0 },
            producatoriSelected: [],
            claseSelected: [],
            simbolClaseSelected: [],
        },
        producatori: {},
        clase: {},
        simbolClase: {},
        shops: {},
        counters: {
            producatoriSelected: {},
            claseSelected: {},
            simbolClaseSelected: {}
        },
        priceRange: { min: 0, max: 0 },
        filteredCount: 0,
        filteredPages: 0,
        count: 0,
        curs: {}
    },
    reducers: {
        loadProducatori(state, action) {
            if (!!action.payload) {
                state.producatori = action.payload;
            }
        },

        loadClase(state, action) {
            if (!!action.payload) {
                state.clase = action.payload;
            }
        },

        loadSimbolClase(state, action) {
            if (!!action.payload) {
                state.simbolClase = action.payload;
            }
        },

        loadShops(state, action) {
            if (!!action.payload) {
                state.shops = action.payload;
            }
        },
        
        loadCurs(state, action) {
            if (!!action.payload) {
                state.curs = action.payload;
                console.log("loadCurs", state.curs);
            }
        },

        loadMenu(state, action) {
            if (!!action.payload) {
                state.menuTree = action.payload;
            }
        },
        
        setSearchQuery(state, action) {
            if (!!action.payload) {
                state.searchQuery = action.payload;
                state.activeMenu = {};
            } else {
                state.searchQuery = "";
            }
            console.log("Global search query", state.searchQuery);
        },

        setActiveMenu(state, action) {
            if (!!action.payload) {
                state.activeMenu = action.payload;
                state.searchQuery = "";
            } else {
                state.activeMenu = {};
            }
            console.log("Global activeMenu", state.activeMenu);
        },
        
        setSearchType(state, action) {
            if (!!action.payload) {
                state.searchType = action.payload;
            } else {
                state.searchType = "";
            }
            console.log("Global search type", state.searchType);
        },

        searchResults(state, action) {
            if (!!action.payload) {
                state.products = action.payload;
                state.filteredProducts = action.payload;
                state.count = action.payload.length;

                // reset all
                state.filters = {
                    byText: '',
                    byStock: [],
                    byPrice: { min: 0, max: 0 },
                    producatoriSelected: [],
                    claseSelected: [],
                    simbolClaseSelected: [],
                };
                state.counters = {
                    producatoriSelected: {},
                    claseSelected: {},
                    simbolClaseSelected: {}
                };
                state.priceRange = { min: 0, max: 0 };
                state.filteredCount = 0;
                state.filteredPages = 0;

                recount(state);
                recountAllGroupSelectors(state);
                countPriceRange(state.filteredProducts, state);
                resetPriceRange(state);
            }
        },

        filterByValue(state, action) {
            state.filters.byText = action.payload.value;
            appyAllFilters(state);
            //recountAllGroupSelectors(state);
            //resetPriceRange(state);
        },

        filterByStock(state, action) {
            let idShop = action.payload.idShop
            let isChecked = action.payload.isChecked;
            console.log("filterByStock - before", JSON.parse(JSON.stringify(state.filters.byStock)));
            state.filters.byStock[idShop] = isChecked;
            console.log("filterByStock", action.payload.idShop, action.payload.isChecked, JSON.parse(JSON.stringify(state.filters.byStock)));
            appyAllFilters(state);
            //recountAllGroupSelectors(state);
            //resetPriceRange(state);
        },

        filterBySimbolClasa(state, action) {
            let id = parseInt(action.payload.id);
            let checked = action.payload.checked;

            console.log("Set filterBySimbolClasa ", id, checked);
            if (checked) {
                state.filters.simbolClaseSelected[id] = id;
            } else {
                state.filters.simbolClaseSelected = state.filters.simbolClaseSelected
                    .filter(i => {
                        return i !== id
                    });
            }

            appyAllFilters(state);
            //resetPriceRange(state);
        },

        filterByClasa(state, action) {
            let display = action.payload.display;
            let hash = hashCode(display);

            let checked = action.payload.checked;

            if (checked) {
                if (!state.filters.claseSelected.includes(hash)) {
                    state.filters.claseSelected.push(hash);
                }
            } else {
                const index = state.filters.claseSelected.indexOf(hash);
                if (index > -1) { // only splice array when item is found
                    state.filters.claseSelected.splice(index, 1); // 2nd parameter means remove one item only
                }
            }

            let temp = JSON.parse(JSON.stringify(state.filters.claseSelected));
            console.log("Set filterByClasa ", temp);
            appyAllFilters(state);
            //resetPriceRange(state);
        },

        filterByProducator(state, action) {
            let id = parseInt(action.payload.id);
            let checked = action.payload.checked;

            console.log("Set filterByProducator ", id, checked);
            if (checked) {
                state.filters.producatoriSelected[id] = id;
            } else {
                state.filters.producatoriSelected[id] = 0;
            }

            appyAllFilters(state);
            //resetPriceRange(state);
        },

        filterByPrice(state, action) {
            state.filters.byPrice.min = action.payload.min;
            state.filters.byPrice.max = action.payload.max;
            if (state.filteredCount) {
                appyAllFilters(state);
                //recountAllGroupSelectors(state);
            }
        }
    }
})

export const {
    setSearchQuery, setSearchType, setActiveMenu, 
    searchResults,
    loadProducatori, loadClase, loadSimbolClase, loadShops, loadCurs, loadMenu,
    filterByClasa, filterByValue, filterBySimbolClasa, filterByProducator, filterByPrice, filterByStock
} = productsSlice.actions
export default productsSlice.reducer