import { Form, Button, Tabs, Tab } from "react-bootstrap";
import { ApiGet, ApiPost } from "services/ApiService";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setError } from "store/msgStore";

export default function AdminMentorRest() {
    var [data, setData] = useState();
    var [command, setCommand] = useState();
    var [payload, setPayload] = useState();
    var [mentor, setMentor] = useState();
    var dispatch = useDispatch();
    var [key, setKey] = useState('form');

    useEffect(() => { }, [data, mentor]);

    useEffect(() => {
        ApiGet('/admin-mentor', (data) => {
            setMentor(data)
        },
            (error) => { dispatch(setError(error)); });
    }, []);

    const onSubmitGet = (e) => {
        e.preventDefault();

        console.log("Send WinMentor REST", command);

        let payloadBackend = { "command": command, "method": "GET" }
        ApiPost("/admin-mentor",
            payloadBackend,
            (data) => setData(data),
            (error) => { dispatch(setError(error)); });
    }

    const escape = (str) => {
        return JSON.parse(JSON.stringify(str))
      }
      
    const onSubmitPost = (e) => {
        e.preventDefault();

        console.log("Send WinMentor REST", command);

        let encodedPayload = escape(payload)
        let payloadBackend = { "command": command, "method": "POST", "payload": encodedPayload }
        ApiPost("/admin-mentor",
            payloadBackend,
            (data) => setData(data),
            (error) => { dispatch(setError(error)); });
    }

    const onChangeCommand = (e) => {
        setCommand(e.target.value)
    }

    const onChangePayload = (e) => {
        setPayload(e.target.value)
    }

    return (
        <>
            <h1>WinMentor - REST Server</h1>
            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3"
            >
                <Tab eventKey="get" title="GET">
                    <Form>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Comanda REST-Server</Form.Label>
                            <Form.Control type="text" placeholder="command" onChange={onChangeCommand} />
                            <Form.Text className="text-muted">
                                Comanda WinMentor rest server
                            </Form.Text>
                        </Form.Group>
                        <p>
                            Exemple:
                            <ul>
                                <li>/GetInfoArticol/&lt;<em>CodExtern</em>&gt;</li>
                                <li>/GetListaGestiuni</li>
                                <li>/GetInfoPartener/RestServer/RO18341159</li>
                                <li>/GetListaGestiuni</li>
                            </ul>
                        </p>
                        <Button variant="primary" type="submit" onClick={onSubmitGet}>GET</Button>
                    </Form>

                </Tab>
                <Tab eventKey="post" title="POST">
                    <Form>
                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Comanda REST-Server</Form.Label>
                            <Form.Control type="text" placeholder="command" onChange={onChangeCommand} />
                            <Form.Text className="text-muted">
                                Comanda WinMentor rest server
                            </Form.Text>
                            <Form.Label>Payload</Form.Label>
                            <Form.Control as="textarea" rows={4} placeholder='payload json' onChange={onChangePayload} />
                        </Form.Group>
                        <p>
                            Exemple:
                            <ul>
                                <li>/%22getInfoParteneri%22</li>
                            </ul>
                        </p>
                        <Button variant="warning" type="submit" onClick={onSubmitPost}>POST</Button>
                    </Form>
                </Tab>
            </Tabs>

            <h3 className="mt-5">Raspuns</h3>
            <p className="mt-3 p-1" style={{ border: '1px solid orange', backgroundColor: '#323232', color: 'orange', fontSize: '.8em' }}>
                <pre>
                    {JSON.stringify(data, null, 2)}
                </pre>
            </p>

            <h3>Info</h3>
            <p>Adresa curenta WinMentor REST-Server:
                <pre className="text-success p-1" style={{ border: '1px solid orange', backgroundColor: '#f3f3f3', color: 'blue', fontSize: '.7em' }}>{mentor}</pre></p>
        </>
    )
}