import SearchResults from "./products/SearchResults";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActiveMenu, setSearchQuery } from "store/productsReducer";

export default function Search({ currentUser }) {
    const params = useParams(); // parameters from URL (react-router)
    const tree = useSelector((store) => store.products.menuTree);

    const dispatch = useDispatch();
    //const location = useLocation();

    useEffect(() => {
        console.log("Search page params", params);
        if (!!params.type && params.type === 'cauta') {
            dispatch(setSearchQuery(params.query));
        }
        if (!!params.type && params.type === 'categorie' && !!tree && !!tree.find) {
            const menuItem = tree.find((item) => item.clasaWeb === params.query);
            dispatch(setActiveMenu(menuItem));
        }

    }, [dispatch, params, tree, params.type, params.query]);

    return (
        currentUser ? <SearchResults currentUser={currentUser} />
            : ''
    );
}